import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { NumberInputConfig } from '@app/shared/interfaces/input.interface';

@Component({
  selector: 'sliqpay-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {
  @Input() config!: NumberInputConfig | null;
  @Input() value = 0;
  @Output() focusOut = new EventEmitter();
  @Output() changed = new EventEmitter();

  onChange!: (value: number) => void;
  onTouch!: () => void;

  disabled?: boolean;

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  get isRequired(): boolean {
    return this.control.control?.hasValidator(Validators.required) || false;
  }

  public get hasError(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateFormValue(): void {
    this.onChange(this.value);
    this.changed.emit(this.value);
  }

  onBlur(): void {
    this.focusOut.emit(this.value);
    this.onTouch();
  }

  increment(): void {
    this.value++;
    this.updateFormValue();
  }

  decrement(): void {
    if (this.value - 1 < 0 && !this.config?.allowNegative) {
      return;
    }

    this.value--;
    this.updateFormValue();
  }

  onInputChange(): void {
    this.changed.emit(this.value);
  }
}
