import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sliqpay-dot-loader',
  templateUrl: './dot-loader.component.html',
  styleUrls: ['./dot-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DotLoaderComponent implements OnInit {
  @Input() theme: 'light' | 'primary' = 'light';

  constructor() {}

  ngOnInit(): void {}
}
