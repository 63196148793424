import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { NgxPaginationModule } from 'ngx-pagination';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSliderModule } from '@angular/material/slider';
import { DateMaskDirective } from '@app/core/directives/date-mask/date-mask.directive';
import { StatusBtnClassDirective } from '@app/core/directives/status-btn-class/status-btn-class.directive';
import { ThSortableDirective } from '@app/core/directives/th-sortable/th-sortable.directive';
import { NgVarDirective } from '@app/core/directives/var/ng-var.directive';
import { TermsConditionsModalComponent } from '@app/core/pages/terms-conditions-modal/terms-conditions-modal.component';
import { SharedModule } from '@app/shared/shared.module';
import { EmptyScreenComponent } from '@core/components/empty-screen/empty-screen.component';
import { ModalPdfViewerComponent } from '@core/components/modal-pdf-viewer/modal-pdf-viewer.component';
import { ModalComponent } from '@core/components/modal/modal.component';
import { PaginationComponent } from '@core/components/pagination/pagination.component';
import * as CDA from '@core/services/custom-date-adapter';
import { FullscreenLoaderComponent } from './components/fullscreen-loader/fullscreen-loader.component';
import { SupportRequestFormComponent } from './components/support-request-form/support-request-form.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { BrandFeatureFlagDirective } from './directives/brand-feature-flag/brand-feature-flag.directive';
import { ProviderConfigFeatureFlagDirective } from './directives/provider-config-feature-flag/provider-config-feature-flag.directive';
import { NotFoundComponent } from './pages/not-found/not-found.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    PaginationComponent,
    SupportRequestFormComponent,
    ModalPdfViewerComponent,
    ModalComponent,
    EmptyScreenComponent,
    StatusBtnClassDirective,
    ThSortableDirective,
    NgVarDirective,
    DateMaskDirective,
    TermsConditionsModalComponent,
    TermsAndConditionsComponent,
    FullscreenLoaderComponent,
    ProviderConfigFeatureFlagDirective,
    BrandFeatureFlagDirective
  ],
  imports: [
    NgbModule,
    FormsModule,
    RouterModule,
    CommonModule,
    DragDropModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    PdfViewerModule,
    AngularSignaturePadModule,
    MatDatepickerModule,
    MatSliderModule,
    SharedModule,
    MatGridListModule
  ],
  exports: [
    NgbModule,
    FormsModule,
    DragDropModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    AngularSignaturePadModule,
    RouterModule,
    PaginationComponent,
    EmptyScreenComponent,
    SupportRequestFormComponent,
    ModalPdfViewerComponent,
    StatusBtnClassDirective,
    ThSortableDirective,
    NgVarDirective,
    DateMaskDirective,
    MatDatepickerModule,
    MatGridListModule,
    TermsAndConditionsComponent,
    FullscreenLoaderComponent,
    ProviderConfigFeatureFlagDirective,
    BrandFeatureFlagDirective
  ],
  providers: [
    NgbActiveModal,
    {
      provide: DateAdapter,
      useClass: CDA.CustomDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: CDA.MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' }
  ]
})
export class CoreModule {}
