<form class="form-group" [formGroup]="form">
  <label
    class="text-truncate w-100"
    *ngIf="config?.label"
    [ngClass]="{
      disabled: disabled,
      'required-field': isRequired && config?.showRequiredMarker && !disabled
    }"
    >{{ config?.label }}</label
  >
  <div class="search-date-group">
    <i class="uil uil-calendar-alt" (click)="picker.open()"></i>
    <div class="input-group l-datepicker">
      <input
        [matDatepicker]="picker"
        [placeholder]="config?.placeholder"
        [disabled]="disabled"
        [min]="minDate"
        [max]="maxDate"
        [class.border--danger]="!disabled && hasError && control.touched"
        (click)="picker.open()"
        (dateChange)="onDateChange()"
        matInput
        formControlName="dateInput"
        class="form-control pp-datepicker"
      />
      <mat-datepicker
        #picker
        [disabled]="disabled"
        [startView]="config?.startView || 'month'"
        (closed)="onClose()"
        (opened)="onOpened()"
        (monthSelected)="setMonthAndYear($event, picker)"
      ></mat-datepicker>
    </div>
    <i class="uil uil-angle-down cursor-pointer" (click)="picker.open()"></i>

    <div class="invalid-feedback d-block" *ngIf="!disabled && hasError && control.touched">
      <div *ngIf="hasError?.required || hasError?.invalid">
        {{ config?.errors?.required }}
      </div>
      <div *ngIf="hasError?.valueLessThanMinDate">{{ config?.errors?.valueLessThanMinDate }}</div>
      <div *ngIf="hasError?.valueGreaterThanMaxDate">{{ config?.errors?.valueGreaterThanMaxDate }}</div>
      <div *ngIf="hasError?.isDOBMinYears">
        Date of birth should on or before
        <span>{{ maxDate | date: 'd MMMM yyyy' }}</span>
      </div>
      <div *ngIf="hasError?.isDOBMinYearsToday">
        Date of birth should on or before
        <span>{{ today | date: 'd MMMM yyyy' }}</span>
      </div>
    </div>
  </div>
</form>
