import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AmendmentQuote, FirstStepFields } from '@core/models';
import { HelperService } from '@core/services/helper.service';
import { PaymentService } from '@core/services/payment.service';
import { environment } from '@environments/environment';
import { format } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentFrequency } from '../enums/payment-frequency.enum';
import { PlanType } from '../enums/plan-type.enum';
import { UserSettingsService } from './user-settings/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class PlanCalculationService {
  objUser = this.helperService.getObjUser() || null;
  treatmentTypeOrtho = this.objUser?.validation_values.DDR_TREATMENT.ORTHO;
  minDeposit = 0;
  minTermMonths = 0;
  minTreatmentCost = 0;
  maxTreatmentCost = 0;
  minAdditionalPlanAmount = 1;
  minDepositAmount = 0;
  minDepositInAmtBNPL = 0;
  maxTreatmentCostBNPL = 0;
  computeMinTermMonths = 1;
  paymentPlanAmount2k = 2000;
  defaultWeeklyNoOfPayments = 12;
  calcMinTreatmentCostBNPL = 1;
  maxTermMonthsConnect = 36;
  public radioComplianceNoLbl = 'complianceNo';

  maxDDRMonth = this.objUser?.segment.zmaxDDRMonth;
  stepDeposit = this.objUser?.segment.zstepDeposit;
  maxTotalTreatmentCost = this.objUser?.segment.zmaxTotalTreatmentCost;
  maxStarDateInDays = this.objUser?.segment.zmaxStartDateInDays;
  maxAmendmentIncreasePlan = this.objUser?.segment.zmaxAmendmentIncreasePlan;
  minDepositRatio = this.objUser?.segment.zminDepositRatio;
  minDepositRatioBNPL = this.objUser?.segment.zminDepositRatio;
  maxTermMonths = this.objUser?.segment.zmaxTermMonths || null;
  minDepositAmtDenOrtho = this.objUser?.segment.zminDepositAmtDenOrtho; // Dental With Ortho Treatment Type
  minPaymentPlan = this.objUser?.segment.custrecord_minimum_amount_plan;
  minPaymentPlanBNPL = this.objUser?.segment.custrecord_minimum_amount_plan;
  maxTreatmentCostDenOrtho = this.objUser?.segment.zmaxTreatmentCostDenOrtho; // Dental With Ortho Treatment Type
  maxTermMonthsBNPL = this.objUser?.segment.zmaxTermMonths;
  maxDDRMonthBNPL = this.objUser?.segment.zmaxDDRMonth;
  stepTreatmentCostBNPL = this.objUser?.segment.zstepTreatmentCost;
  stepTreatmentCost = this.objUser?.segment.zstepTreatmentCost;
  calculatorOutput = {
    directDebit: {
      cost: 0.0,
      per: ''
    },
    periodOf: {
      months: 0,
      weeks: 0
    },
    numberOfDebits: 0,
    firstPaymentOn: new Date(),
    finalPaymentOn: new Date()
  };
  maxRepaymentData = {
    valid: true,
    maxRepaymentAmountByFreq: 0
  };
  maxDeposit = this.maxTreatmentCost - this.minPaymentPlan;
  minDepositInAmt = this.minDepositRatio * this.maxTreatmentCost;
  stepDepositBNPL = this.objUser?.segment.zstepDeposit;
  treatmentTypeDental = this.objUser?.validation_values.DDR_TREATMENT.DENTAL;
  nonGuaranteePlan = this.objUser?.validation_values.DDR_PLAN_TYPE.NON_GUARANTEED;
  today = new Date();
  tomorrow = this.helperService.addDaysToDate(1);
  maxFortnightlyNoOfPayments = 5;
  hideFornightly = false;

  maxDepositObs$ = new BehaviorSubject(0);

  constructor(
    private helperService: HelperService,
    private paymentService: PaymentService,
    private userSettingsService: UserSettingsService
  ) {}

  public calculateTreatmentFeesNew(formValue: any) {
    const planAmount = formValue.payment_plan_total || 0;
    const paymentAmount = formValue.custrecord_mfa_ddr_single_payments || 0;
    const amountRequiredToPay = formValue.total_plan_value - formValue.deposit;
    const newQuote = formValue.quote;

    formValue.quote.forEach((quote: any, i: number) => {
      const weeklyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(1, quote.term_months, planAmount, paymentAmount),
        amountRequiredToPay
      );
      const fortnightlyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(2, quote.term_months, planAmount, paymentAmount),
        amountRequiredToPay
      );
      const monthlyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(3, quote.term_months, planAmount, paymentAmount),
        amountRequiredToPay
      );
      newQuote[i]['weekly'] = weeklyCost;
      newQuote[i] = {
        ...newQuote[i],
        weekly: weeklyCost,
        fortnightly: fortnightlyCost,
        monthly: monthlyCost
      };
    });
    return newQuote;
  }

  public calculateTreatmentFee(firstStepGroup: UntypedFormGroup) {
    const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;
    const planAmount = firstStepGroup.value.payment_plan_total || 0;
    const paymentAmount = firstStepGroup.value.custrecord_mfa_ddr_single_payments || 0;
    const amountRequiredToPay = firstStepGroup.value.total_plan_value - firstStepGroup.value.deposit;

    quoteGroup.controls.forEach((quote) => {
      this.calculatePaymentPlanAmount(firstStepGroup);

      const weeklyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(1, quote.get('term_months')?.value, planAmount, paymentAmount),
        amountRequiredToPay
      );
      const fortnightlyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(2, quote.get('term_months')?.value, planAmount, paymentAmount),
        amountRequiredToPay
      );
      const monthlyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(3, quote.get('term_months')?.value, planAmount, paymentAmount),
        amountRequiredToPay
      );
      quote.get('weekly')?.setValue(weeklyCost);
      quote.get('fortnightly')?.setValue(fortnightlyCost);
      quote.get('monthly')?.setValue(monthlyCost);
    });
  }

  public generateAmendmentQuotes(planAmount: number, directDebitAmount: number, currentQuote: AmendmentQuote[]): Array<AmendmentQuote> {
    return currentQuote.reduce<AmendmentQuote[]>((acc, _quote) => {
      const weeklyTotalDebits = this.calculateNumberOfDebits(PaymentFrequency.Weekly, _quote.term_months, planAmount, directDebitAmount);
      const weeklyCost = this.calculatePerUnitCost(weeklyTotalDebits, planAmount);

      const fortnightlyTotalDebits = this.calculateNumberOfDebits(
        PaymentFrequency.Fortnightly,
        _quote.term_months,
        planAmount,
        directDebitAmount
      );
      const fortnightlyCost = this.calculatePerUnitCost(fortnightlyTotalDebits, planAmount);

      const monthlyTotalDebits = this.calculateNumberOfDebits(PaymentFrequency.Monthly, _quote.term_months, planAmount, directDebitAmount);
      const monthlyCost = this.calculatePerUnitCost(monthlyTotalDebits, planAmount);

      const newQuote: AmendmentQuote = {
        ..._quote,
        weekly: weeklyCost,
        fortnightly: fortnightlyCost,
        monthly: monthlyCost
      };

      return [...acc, newQuote];
    }, []);
  }

  public calculateTreatmentFees(firstStepGroup: UntypedFormGroup, event?: any) {
    if (firstStepGroup.get('plan_type')?.value === PlanType.Bnpl) {
      return this.calculateTreatmentFeesBnpl(firstStepGroup);
    } else {
      return this.calculateTreatmentFee(firstStepGroup);
    }
  }

  public maxRepaymentValid(
    frequency: string,
    repaymentAnount: number,
    maxMonthlyRepaymentAmount = this.maxDDRMonth
  ): { valid: boolean; maxRepaymentAmountByFreq: number } {
    let returnedData = {
      valid: true,
      maxRepaymentAmountByFreq: 0
    };
    let maxRepaymentAmountByFreq = maxMonthlyRepaymentAmount;
    switch (parseInt(frequency, 10)) {
      // weekly payments
      case 1:
        maxRepaymentAmountByFreq = maxRepaymentAmountByFreq / 4;
        if (repaymentAnount > maxRepaymentAmountByFreq) {
          returnedData = {
            valid: false,
            maxRepaymentAmountByFreq
          };
        }
        break;

      // fortnightly payments
      case 2:
        maxRepaymentAmountByFreq = maxRepaymentAmountByFreq / 2;
        if (repaymentAnount > maxRepaymentAmountByFreq) {
          returnedData = {
            valid: false,
            maxRepaymentAmountByFreq
          };
        }
        break;

      // monthly payments
      case 3:
        if (repaymentAnount > maxRepaymentAmountByFreq) {
          returnedData = {
            valid: false,
            maxRepaymentAmountByFreq
          };
        }
        break;
    }
    this.maxRepaymentData = returnedData;
    return returnedData;
  }

  public calculatePerUnitCost(numberOfDebits: number, amountRequiredToPay: number): number {
    // calculate the amount per unit
    let amountPerUnit = amountRequiredToPay / numberOfDebits;

    amountPerUnit = amountPerUnit ? amountPerUnit : 0;

    // https://stackoverflow.com/questions/10413573/rounding-up-to-the-nearest-0-05-in-javascript
    const ceil = Math.ceil(amountPerUnit * 20);
    // console.log('ceil ' + ceil);

    amountPerUnit = ceil / 20;

    // return parseFloat(amountPerUnit.toFixed(2));

    return numberOfDebits !== 0 ? Math.round((amountPerUnit ? amountPerUnit : 0) * 100) / 100 : 0;
  }

  public checkDentalProviderOrthoTreatment(form: UntypedFormGroup): boolean {
    return (
      this.objUser?.clinic_type === '1' &&
      form.get('treatment_type')?.value === this.treatmentTypeOrtho &&
      form.get('plan_type')?.value !== PlanType.Bnpl
    );
  }

  public calculateNumberOfDebits(frequency: string | number, months: number, planAmount: number, debitAmount: number): number {
    let numberOfDebits = 0;
    const weeks = 52;

    if (months === 0) {
      if (planAmount && debitAmount) {
        while (planAmount > 0) {
          numberOfDebits++;
          planAmount -= debitAmount;
        }
      }
      return numberOfDebits;
    } else {
      switch (parseInt(frequency as string, 10)) {
        // weekly payments
        case 1:
          // numberOfDebits = months * 4.428 + weeks;
          numberOfDebits = (months / 12) * weeks;
          break;
        // fortnightly payments
        case 2:
          // numberOfDebits = (months * 4.428 + weeks) / 2;
          numberOfDebits = (months / 12) * (weeks / 2);
          break;
        // monthly payments
        case 3:
          // numberOfDebits = months + weeks / 4.428;
          numberOfDebits = months;
          break;
      }
    }

    // return Math.ceil(numberOfDebits);
    return Math.floor(numberOfDebits);
  }

  public minTreatmentCostValid(treatmentCost: number, firstStepFields: FirstStepFields): boolean {
    return treatmentCost >= this.calcMinTreatmentCost(firstStepFields);
  }

  public calcMinTreatmentCost(firstStepFields: FirstStepFields): number {
    if (this.checkDentalProvidernOrthoTreatment(firstStepFields)) {
      return parseFloat(this.minPaymentPlan) + this.minDepositAmtDenOrtho;
    } else {
      return this.minPaymentPlan / (1 - this.minDepositRatio);
    }
  }

  public checkDentalProvidernOrthoTreatment(firstStepFields: FirstStepFields): boolean {
    this.objUser = this.helperService.getObjUser();
    return (
      this.objUser?.clinic_type === '1' &&
      parseInt(firstStepFields.treatment_type, 10) === this.treatmentTypeOrtho &&
      firstStepFields.plan_type !== PlanType.Bnpl
    );
  }

  public computeMaxTermMonths(totalPlanAmount: number): number {
    this.objUser = this.helperService.getObjUser();
    if (totalPlanAmount <= this.paymentPlanAmount2k) {
      if (this.objUser.segment.id[0].value == 2 && this.userSettingsService.isProactiveConnectOnlyProvider()) {
        // Ortho Segment && Connect Only
        return this.maxTermMonths;
      } else {
        return this.objUser.segment.custrecord_maximum_term_2000;
      }
    } else {
      return this.objUser.segment.custrecord_maximum_term;
    }
  }

  public maxTermMonthValid(selectedTermMonths: any, totalPlanAmount: number): boolean {
    return selectedTermMonths <= this.computeMaxTermMonths(totalPlanAmount);
  }

  public maxDepositValid(form: UntypedFormGroup): boolean {
    if (form.get('total_plan_value')?.valid) {
      if (this.minTreatmentCostValid(form.get('total_plan_value')?.value, form.value)) {
        return form.get('deposit')?.value <= Math.round(this.calcMaxDeposit(form.value) * 100) / 100;
      } else {
        return true;
      }
    }
    return true;
  }

  public calcMaxDeposit(firstStepFields: FirstStepFields): number {
    if (this.objUser?.clinic_type === '2') {
      if (firstStepFields.total_plan_value === this.minPaymentPlan) {
        return firstStepFields.total_plan_value * this.calcMinDeposit(firstStepFields);
      } else if (firstStepFields.total_plan_value - this.minPaymentPlan > 0) {
        return firstStepFields.total_plan_value - this.minPaymentPlan;
      } else {
        return 0;
      }
    } else {
      return firstStepFields.total_plan_value - this.minPaymentPlan > 0 ? firstStepFields.total_plan_value - this.minPaymentPlan : 0;
    }
  }

  public calcMinDeposit(firstStepFields: FirstStepFields): number {
    this.objUser = this.helperService.getObjUser();
    let minDepositForSelectedTreatmentCost = firstStepFields.total_plan_value * this.minDepositRatio;

    if (this.checkDentalProvidernOrthoTreatment(firstStepFields)) {
      minDepositForSelectedTreatmentCost = this.minDepositAmtDenOrtho;
    } else {
      minDepositForSelectedTreatmentCost = firstStepFields.total_plan_value * this.minDepositRatio;
    }

    if (this.objUser?.clinic_type === '2') {
      if (minDepositForSelectedTreatmentCost === 0) {
        if (firstStepFields.total_plan_value - minDepositForSelectedTreatmentCost > this.maxTotalTreatmentCost) {
          return firstStepFields.total_plan_value - this.maxTotalTreatmentCost;
        } else {
          return 0;
        }
      }
    } else {
      if (firstStepFields.total_plan_value - minDepositForSelectedTreatmentCost > this.maxTotalTreatmentCost) {
        return firstStepFields.total_plan_value - this.maxTotalTreatmentCost;
      } else {
        return minDepositForSelectedTreatmentCost;
      }
    }
    return 0;
  }

  public minDepositValid(firstStepFields: FirstStepFields): boolean {
    if (this.checkDentalProvidernOrthoTreatment(firstStepFields)) {
      return firstStepFields.deposit >= this.minDepositAmtDenOrtho;
    } else {
      if (firstStepFields.total_plan_value) {
        return firstStepFields.deposit >= Math.round(this.calcMinDeposit(firstStepFields) * 100) / 100;
      }
    }
    return true;
  }

  public setMaxTreatmentCost(firstStepFields: FirstStepFields): void {
    this.objUser = this.helperService.getObjUser();
    this.minPaymentPlan = this.objUser?.segment.custrecord_minimum_amount_plan;
    this.maxTermMonths = this.objUser?.segment.custrecord_maximum_term;
    this.minDepositInAmt = this.minDepositRatio * this.maxTreatmentCost;

    // BNPL
    this.minPaymentPlanBNPL = this.objUser?.segment.custrecord_minimum_amount_plan;
    this.minDepositInAmtBNPL = this.minDepositRatioBNPL * this.maxTreatmentCostBNPL;

    // TODO
    // if (this.objUser?.clinic_type === '2') {
    //   // Ortho
    //   // this.maxTreatmentCost = 25000;
    //   this.maxTreatmentCost = this.maxTotalTreatmentCost;
    // } else if (this.objUser?.clinic_type === '1') {
    //   // Dental
    //   this.maxTreatmentCost = this.maxTotalTreatmentCost;
    // } else {
    // }

    this.maxTreatmentCost = this.maxTotalTreatmentCost;

    try {
      // No treatment type selection
      if (this.checkDentalProvidernOrthoTreatment(firstStepFields)) {
        this.maxTreatmentCost = this.maxTreatmentCostDenOrtho;
      }
    } catch (error) {}
    this.maxDeposit = this.maxTreatmentCost - this.minPaymentPlan;
    this.maxDepositObs$.next(this.maxDeposit);
  }

  public setMinDepositRatio(minDepositRatio: number): void {
    this.minDepositRatio = minDepositRatio;
  }

  public minDepositForSelectedTreatmentCost(firstStepFieldsGroup: UntypedFormGroup): number {
    let tempTotalDeposit;

    if (this.checkDentalProvidernOrthoTreatment(firstStepFieldsGroup.value)) {
      tempTotalDeposit = this.minDepositAmtDenOrtho;

      // Retain deposit amount entered
      if (firstStepFieldsGroup.get('deposit')?.value && firstStepFieldsGroup.get('deposit')?.value > tempTotalDeposit) {
        return firstStepFieldsGroup.get('deposit')?.value;
      }
    } else {
      tempTotalDeposit = firstStepFieldsGroup.get('total_plan_value')?.value * this.minDepositRatio;

      // Retain deposit amount entered
      if (
        firstStepFieldsGroup.get('plan_type')?.value === PlanType.Guaranteed &&
        firstStepFieldsGroup.get('deposit')?.value &&
        firstStepFieldsGroup.get('deposit')?.value > tempTotalDeposit
      ) {
        return firstStepFieldsGroup.get('deposit')?.value;
      }
    }

    return tempTotalDeposit;
  }

  public calculateDeposit(firstStepFieldsGroup: UntypedFormGroup): void {
    this.minDepositAmount = this.calculateMinDepositAmt(firstStepFieldsGroup, this.minDepositRatio);
  }

  public calculateMinDepositAmt(firstStepFields: UntypedFormGroup, minDepositRatio: number): number {
    let tempTotalDeposit;
    if (this.checkDentalProvidernOrthoTreatment(firstStepFields.value)) {
      tempTotalDeposit = this.minDepositAmtDenOrtho;

      // Retain deposit amount entered
      if (firstStepFields.get('deposit')?.value && firstStepFields.get('deposit')?.value > tempTotalDeposit) {
        return firstStepFields.get('deposit')?.value;
      } else {
        return tempTotalDeposit;
      }
    } else {
      tempTotalDeposit = firstStepFields.get('total_plan_value')?.value * this.minDepositRatio;

      // Retain deposit amount entered
      if (
        firstStepFields.get('plan_type')?.value === PlanType.Guaranteed &&
        firstStepFields.get('deposit')?.value &&
        firstStepFields.get('deposit')?.value > tempTotalDeposit
      ) {
        return firstStepFields.get('deposit')?.value;
      } else {
        return firstStepFields.get('total_plan_value')?.value * minDepositRatio;
      }
    }
  }

  public getBNPLFrequencies(frequencies: any) {
    // filter to exlcude 3 => Monthly
    return frequencies.filter((item: any) => item.internalid !== '3');
  }

  public setDDRSinglePayments(frequency: any, frequencies: any[]): string | null {
    if (frequency) {
      return this.helperService.strtolower(
        this.helperService.getObjectByValue(frequencies, 'internalid', frequency)
          ? this.helperService.getObjectByValue(frequencies, 'internalid', frequency).name
          : ''
      );
    }
    return null;
  }

  public getPaymentFrequencyMultiplier(frequency: string, isDays = false): number {
    let days = 0;
    let multiplier = 0;
    switch (parseInt(frequency, 10)) {
      // weekly payments
      case 1:
        days = 7;
        // multiplier = 4;
        multiplier = 52 / 12;
        break;
      // fortnightly payments
      case 2:
        days = 14;
        // multiplier = 2;
        multiplier = 26 / 12;
        break;
      // monthly payments
      case 3:
        days = 1;
        multiplier = 1;
        break;
    }
    return isDays ? days : multiplier;
  }

  public setCalculatorOutput(firstStepGroup: UntypedFormGroup) {
    const periods = ['null', 'week', 'fortnight', 'month'];

    const plan = {
      option: 0,
      frequency: firstStepGroup.get('payment_freq')?.value
    };

    const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;
    const period = periods[plan.frequency];

    if (!quoteGroup.controls[plan.option]?.value.term_months) {
      quoteGroup.controls[plan.option].value.term_months =
        firstStepGroup.get('no_of_payments')?.value && firstStepGroup.get('payment_freq')?.value
          ? Math.ceil(
              firstStepGroup.get('no_of_payments')?.value / this.getPaymentFrequencyMultiplier(firstStepGroup.get('payment_freq')?.value)
            )
          : 0;
    }

    const months = plan.option !== null ? quoteGroup.controls[plan.option]?.value.term_months : 0;
    const planAmount = firstStepGroup.get('payment_plan_total')?.value;
    const paymentAmount = firstStepGroup.get('custrecord_mfa_ddr_single_payments')?.value;
    const numberOfDebits = this.calculateNumberOfDebits(plan.frequency, months, planAmount, paymentAmount);
    const amountRequiredToPay = firstStepGroup.get('total_plan_value')?.value - firstStepGroup.get('deposit')?.value;
    this.calculatorOutput = {
      directDebit: {
        cost:
          firstStepGroup.get('plan_type')?.value === 2
            ? this.calculatePerUnitCost(numberOfDebits, amountRequiredToPay)
            : firstStepGroup.get('custrecord_mfa_ddr_single_payments')?.value,
        per: period
      },
      periodOf: {
        months,
        weeks: 0
      },
      numberOfDebits,
      firstPaymentOn: firstStepGroup.get('start_date')?.value !== '' ? new Date(firstStepGroup.get('start_date')?.value) : new Date(),
      finalPaymentOn: new Date()
    };
  }

  public calculateTermMonths(numberOfPayments: number, frequency: string): number {
    if (!numberOfPayments || !frequency) {
      return 0;
    }
    return Math.ceil(numberOfPayments / this.getPaymentFrequencyMultiplier(frequency));
  }

  public validateRepayment(firstStepGroup: UntypedFormGroup, frequencies: any) {
    try {
      const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;

      const freqName = this.helperService.strtolower(
        this.helperService.getObjectByValue(frequencies, 'internalid', firstStepGroup.get('payment_freq')?.value)
          ? this.helperService.getObjectByValue(frequencies, 'internalid', firstStepGroup.get('payment_freq')?.value).name
          : ''
      );

      if (freqName !== '') {
        this.maxRepaymentValid(firstStepGroup.get('payment_freq')?.value, quoteGroup.controls[0].get(freqName)?.value, this.maxDDRMonth);
      }
    } catch (error) {}
  }

  public minTermMonthValid(firstStepGroup: UntypedFormGroup, selectedTermMonths: number): boolean {
    const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;

    if (quoteGroup?.controls[0].get('term_months')?.touched) {
      return selectedTermMonths >= this.computeMinTermMonths;
    } else {
      return true;
    }
  }

  public calculatePaymentPlanAmount(firstStepFields: UntypedFormGroup) {
    let ppAmount = 0;
    if (firstStepFields.get('total_plan_value')?.valid) {
      ppAmount = Math.round((firstStepFields.get('total_plan_value')?.value - firstStepFields.get('deposit')?.value) * 100) / 100;
    }
    return ppAmount;
  }

  public minTreatmentCostValidBNPL(treatmentCost: number) {
    return treatmentCost >= 1;
  }

  public checkRPRecordnPlanExist(firstStepGroup: UntypedFormGroup, urlParams: any): Observable<any> {
    // eslint-disable-next-line eqeqeq
    const rpDOB =
      typeof firstStepGroup.get('rp_dob')?.value == 'string'
        ? firstStepGroup.get('rp_dob')?.value
        : format(firstStepGroup.get('rp_dob')?.value, 'dd/MM/yyyy');

    const mobileNumber = firstStepGroup.get('rp_mobile_num')?.value;

    const data = {
      altname: firstStepGroup.get('rp_firstname')?.value + ' ' + firstStepGroup.get('rp_lastname')?.value,
      phone: mobileNumber,
      custentity_mfa_rp_rpdob: rpDOB,
      intDDrPlanType: firstStepGroup.get('plan_type')?.value
    };

    return this.paymentService.recordAndPlanCheck(urlParams, data);
  }

  public checkRPEligibility(firstStepGroup: UntypedFormGroup, urlParams: string): Observable<any> {
    // eslint-disable-next-line eqeqeq
    const rpDOB =
      typeof firstStepGroup.get('rp_dob')?.value == 'string'
        ? firstStepGroup.get('rp_dob')?.value
        : format(firstStepGroup.get('rp_dob')?.value, 'dd/MM/yyyy');
    const mobileNumber = firstStepGroup.get('rp_mobile_num')?.value;

    const data = {
      altname: firstStepGroup.get('rp_firstname')?.value + ' ' + firstStepGroup.get('rp_lastname')?.value,
      phone: mobileNumber,
      custentity_mfa_rp_rpdob: rpDOB
    };
    return this.paymentService.checkRPEligibility(urlParams, data);
  }

  // BNPL
  public calculateTreatmentFeesBnpl(firstStepGroup: UntypedFormGroup) {
    const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;
    if (firstStepGroup.get('no_of_payments')?.value && firstStepGroup.get('no_of_payments')?.value !== 0) {
      const plan_amount_by_no_of_payments = this.helperService.formatMoney(
        firstStepGroup.get('total_plan_value')?.value / firstStepGroup.get('no_of_payments')?.value
      );

      quoteGroup.controls.forEach((quote) => {
        this.calculatePaymentPlanAmount(firstStepGroup);

        let weeklyCost;
        let fortnightlyCost;
        let monthlyCost;

        if (firstStepGroup.get('payment_freq')?.value === '2') {
          weeklyCost = this.helperService.formatMoney(
            firstStepGroup.get('total_plan_value')?.value / (firstStepGroup.get('no_of_payments')?.value * 2)
          );
          fortnightlyCost = plan_amount_by_no_of_payments;
          monthlyCost = plan_amount_by_no_of_payments;
        } else {
          weeklyCost = plan_amount_by_no_of_payments;
          fortnightlyCost = this.helperService.formatMoney(
            firstStepGroup.get('total_plan_value')?.value / (firstStepGroup.get('no_of_payments')?.value / 2)
          );
          monthlyCost = plan_amount_by_no_of_payments;
        }
        quote.get('weekly')?.setValue(weeklyCost);
        quote.get('fortnightly')?.setValue(fortnightlyCost);
        quote.get('monthly')?.setValue(monthlyCost);
      });
    }
  }

  // return value based on plantype
  verifySourceType(planType: string | null, value: string, countryOfIssue: string): string {
    if (value === 'PASSPORT') {
      switch (planType) {
        case '3':
        case '2':
          return countryOfIssue.toUpperCase() === environment.defaultCountry.toUpperCase() ? 'LOCAL_PASSPORT' : 'VISA_PASSPORT';
        case '5':
          return 'LOCAL_PASSPORT';
      }
    } else {
      return value;
    }
    return '';
  }

  public verifyResponseType(status: string, isRegistered: string): { label: string; subLabel: string; buttonLabel: string } {
    if (isRegistered || isRegistered === 'true') {
      switch (status) {
        case 'IN_PROGRESS':
          return {
            label: 'Failed to verify',
            subLabel: 'Cannot verify your ID, please try again.',
            buttonLabel: 'Retry'
          };
        case 'VERIFIED':
        case 'VERIFIED_ADMIN':
        case 'VERIFIED_WITH_CHANGES':
          return {
            label: 'Successful ID verification',
            subLabel: '',
            buttonLabel: 'Continue'
          };
        case 'NONE':
        case 'PENDING':
        case 'LOCKED_OUT':
          return {
            label: 'Verification still pending',
            subLabel: 'Please continue filling in your details and we will attempt to verify you shortly.',
            buttonLabel: 'Continue'
          };
        default:
          return {
            label: '',
            subLabel: '',
            buttonLabel: ''
          };
      }
    }
    return {
      label: '',
      subLabel: '',
      buttonLabel: ''
    };
  }

  // NEW
  // TO DO:  Delete all old codes for plan calculation after integrating the new functions in support amendment and template
  isDentalProviderAndOrthoTreatment(treatmentType: string, planType: string): boolean {
    this.objUser = this.helperService.getObjUser();
    return this.objUser?.clinic_type === '1' && treatmentType === this.treatmentTypeOrtho.toString() && planType !== PlanType.Bnpl;
  }

  isOthoProviderAndOrthoTreatment(treatmentType: string, planType: string): boolean {
    this.objUser = this.helperService.getObjUser();
    return this.objUser?.clinic_type === '2' && treatmentType === this.treatmentTypeOrtho.toString() && planType !== PlanType.Bnpl;
  }

  isUserHasBnplAccess(): boolean {
    return this.objUser?.tier_permissions && this.objUser?.tier_permissions?.custrecord_tier_bnpl;
  }

  getPlanTypes(): any[] {
    const planTypes = this.helperService.getObjFormFields()?.custrecord_mfa_ddr_plan_type;

    if (!this.isUserHasBnplAccess()) {
      return planTypes.filter((item: any) => item.internalid !== PlanType.Bnpl);
    }
    return planTypes;
  }
}
