<sliqpay-header class="page-header-sticky" [pageTitle]="'title' | lang: COMPONENT_KEY | async">
  <sliqpay-button *ngIf="hideSaveBtn$ | async" [config]="saveChangesBtnConfig$ | async" (clicked)="saveChanges()"></sliqpay-button>
</sliqpay-header>
<div class="page-content bg-white rounded">
  <div class="page-inner-menu">
    <ul>
      <li>
        <a routerLink="/settings/account" routerLinkActive="is-active">
          <span [innerHTML]="'account' | lang: COMPONENT_KEY | async"></span>
        </a>
      </li>
      <li>
        <a routerLink="/settings/users" routerLinkActive="is-active">
          <span [innerHTML]="'users' | lang: COMPONENT_KEY | async"></span>
        </a>
      </li>
      <li>
        <a routerLink="/settings/tnc" routerLinkActive="is-active">
          <span [innerHTML]="'tnc' | lang: COMPONENT_KEY | async"></span>
        </a>
      </li>
    </ul>
  </div>
  <div class="p-4">
    <router-outlet></router-outlet>
  </div>
</div>
