import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sliqpay-modal-pdf-viewer',
  templateUrl: './modal-pdf-viewer.component.html',
  styleUrls: ['./modal-pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalPdfViewerComponent implements OnInit {
  @Input() data: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
