import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DobService {
  constructor() {}

  // TO DO: Integrate in BE
  getMinAllowedRpAge(): number {
    return 16;
  }

  getDefaultMinDate(): Date {
    return new Date('01/01/1900');
  }

  getMaxAllowedRpDobDate(): Date {
    return new Date(new Date().getFullYear() - this.getMinAllowedRpAge(), new Date().getMonth(), new Date().getDate());
  }

  calculateAge(birthdate: Date): number {
    return moment().diff(birthdate, 'years');
  }
}
