import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { CurrencyInputConfig } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class PayInFullService {
  private CONFIG_KEY = 'pay_in_full';

  constructor(private configService: ConfigService) {}

  getTotalTreatmentCostConfig(): Observable<CurrencyInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'total_treatment_cost');
  }

  getDiscountConfig(): Observable<CurrencyInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'discount');
  }

  getOtherDiscountConfig(): Observable<CurrencyInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'other_discount');
  }

  getPaymentPlanTotalConfig(): Observable<CurrencyInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'payment_plan_total');
  }
}
