<div class="pp-radio-group form-group">
  <label
    class="text-truncate w-100"
    [ngClass]="{ disabled: disableRadioGroup, 'required-field': config?.showRequiredMarker && isRequired && !disabled }"
    >{{ config?.label }}</label
  >

  <div
    class="pp-radio-group__items"
    [class.pp-radio-group__items--error]="hasError && (control.touched || control.dirty)"
  >
    <mat-radio-group
      [disabled]="disableRadioGroup"
      [value]="selectedValue"
      [name]="radioGroupName"
      (change)="onOptionSelected($event)"
    >
      <ng-container *ngFor="let option of options; let i = index">
        <mat-radio-button
          [value]="config && config.bindValue ? option[config.bindValue] : option"
          [disableRipple]="true"
        >
          {{ config && config.bindLabel ? option[config.bindLabel] : option }}
        </mat-radio-button>
        <span *ngIf="i !== options.length - 1" class="pp-radio-group__item-divider">|</span>
      </ng-container>
    </mat-radio-group>
  </div>

  <div class="invalid-feedback d-block" *ngIf="hasError && (control.touched || control.dirty)">
    <div *ngIf="hasError.required">
      {{ config?.errors?.required }}
    </div>
  </div>
</div>
