import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Progress } from '@app/core/models/payment/progress.interface';

@Component({
  selector: 'sliqpay-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnInit {
  @Input() progress!: Progress[];

  constructor() {}

  ngOnInit(): void {}

  getFirstUncompletedStepLabel(): string | undefined {
    return this.progress.find((x: Progress) => x.custrecord_spps_completed === false)?.custrecord_spps_current_label;
  }

  isCurrentUncompletedStep(stepLabel: string): boolean {
    return (
      stepLabel === this.getFirstUncompletedStep() &&
      this.progress.findIndex((x: Progress) => x.custrecord_spps_step_no === this.getFirstUncompletedStep()) !== 0
    );
  }

  private getFirstUncompletedStep(): string | undefined {
    return this.progress.find((x: Progress) => x.custrecord_spps_completed === false)?.custrecord_spps_step_no;
  }
}
