import { DepositStatus } from '../enums/deposit.enum';

export const PaymentPlanDepositStatusLabel = new Map<DepositStatus, string>([
  [DepositStatus.PENDING, 'Deposit hold'],
  [DepositStatus.CLEARED, 'Deposit collected'],
  [DepositStatus.PAID, 'Deposit remitted'],
  [DepositStatus.FAILED, 'Deposit failed'],
  [DepositStatus.PRACTICE_TO_PROCESS, 'Practice to process deposit'],
  [DepositStatus.RELEASED, 'Deposit released']
]);

export const FullPaymentDepositStatusLabel = new Map<DepositStatus, string>([
  [DepositStatus.CLEARED, 'Payment in full collected'],
  [DepositStatus.PAID, 'Payment in full remitted']
]);
