import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { FirstPaymentDateDdItem } from '@app/core/models/common/form-fields.interface';
import { AppDatePicker } from '@app/shared/interfaces/date-picker.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { NumberInputConfig } from '@app/shared/interfaces/input.interface';
import { CustomValidators } from '@app/shared/validators/non-zero.validator';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FirstPaymentDateProposalService } from './first-payment-date-proposal.service';

export enum FpdItemsInternalId {
  Specific = '1',
  Relative = '2'
}

@Component({
  selector: 'sliqpay-first-payment-date-proposal',
  templateUrl: './first-payment-date-proposal.component.html',
  styleUrls: ['./first-payment-date-proposal.component.scss'],
  providers: [FirstPaymentDateProposalService]
})
export class FirstPaymentDateProposalComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'fpd';
  @Input() isTemplate = false; //True for template, false for Create

  FpdItemsInternalId = FpdItemsInternalId;

  dtpConfig$!: Observable<AppDatePicker>;
  relativeInputConfig$!: Observable<NumberInputConfig>;
  relativeDdConfig$!: Observable<AppDropdown>;

  ddItems!: FirstPaymentDateDdItem[];

  selectedDdItem: FirstPaymentDateDdItem = {
    name: '',
    internalid: '',
    max: '0'
  };

  form!: FormGroup;

  tomorrow = this.selfService.tomorrow;
  today = new Date();
  maxStartDateInDays = this.selfService.maxStarDateInDays;

  private unsubscribe$ = new Subject<boolean>();

  get dateType(): AbstractControl | null {
    return this.form.get('type');
  }
  get relativeInput(): AbstractControl | null {
    return this.form.get('relativeInput');
  }
  get specificDate(): AbstractControl | null {
    return this.form.get('specificDate');
  }
  get relativeOption(): AbstractControl | null {
    return this.form.get('relativeOption');
  }
  get hasDateInputError(): ValidationErrors | null {
    return this.relativeInput ? this.relativeInput.errors : null;
  }
  get hasDateDdError(): ValidationErrors | null {
    return this.relativeOption ? this.relativeOption.errors : null;
  }
  get hasDatePickerError(): ValidationErrors | null {
    return this.specificDate ? this.specificDate.errors : null;
  }

  get isRequired(): boolean {
    return this.dateType?.hasValidator(Validators.required) || false;
  }

  constructor(private selfService: FirstPaymentDateProposalService, private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get('firstDebitDate') as FormGroup;

    this.ddItems = this.selfService.getDdItems();
    this.setConfig();

    this.onRadioChange(this.dateType?.value || FpdItemsInternalId.Specific);

    this.dateType?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.onRadioChange(value);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onRadioChange(event: MatRadioChange | string): void {
    const value = typeof event === 'string' ? event : event?.value;

    if (!value) {
      return;
    }

    switch (value) {
      case FpdItemsInternalId.Specific: {
        // Reset input with dd
        this.specificDate?.enable();

        if (!this.isTemplate) {
          this.specificDate?.setValue(this.specificDate?.value || this.selfService.getDefaultFirstPaymentDate());
          this.specificDate?.setValidators(this.selfService.getStartDatePickerValidators());
        } else {
          this.specificDate?.setValue(null);
          this.specificDate?.setValidators(null);
          this.specificDate?.disable();
        }
        this.specificDate?.updateValueAndValidity();

        this.relativeInput?.disable();
        this.relativeInput?.setValue(0);
        this.relativeInput?.setValidators(null);
        this.relativeInput?.updateValueAndValidity();
        this.relativeOption?.disable();
        this.relativeOption?.setValue(null);
        this.relativeOption?.setValidators(null);
        this.relativeOption?.updateValueAndValidity();
        break;
      }
      case FpdItemsInternalId.Relative: {
        // Reset dtp
        this.relativeInput?.enable();
        this.relativeOption?.enable();
        this.relativeInput?.setValue(this.relativeInput?.value || 1);
        if (this.relativeOption?.value) {
          this.onDropdownChange(this.relativeOption?.value);
        }
        this.relativeInput?.setValidators([Validators.min(1)]);
        this.relativeOption?.setValidators(Validators.required);
        this.relativeInput?.updateValueAndValidity();
        this.relativeOption?.updateValueAndValidity();

        this.specificDate?.disable();
        this.specificDate?.setValue(null);
        this.specificDate?.setValidators(null);
        this.specificDate?.updateValueAndValidity();
        break;
      }
      default: {
        break;
      }
    }

    if (this.dateType?.disabled) {
      this.specificDate?.disable();
      this.relativeInput?.disable();
      this.relativeOption?.disable();
    }
  }

  onDropdownChange(event: string): void {
    const selectedDdItem = this.ddItems.find((x) => x.internalid === event);

    if (selectedDdItem) {
      this.selectedDdItem = selectedDdItem;
      this.relativeInput?.setValidators([Validators.required, Validators.min(1), Validators.max(+selectedDdItem?.max)]);
    }
    this.relativeInput?.updateValueAndValidity();
  }

  private setConfig(): void {
    this.dtpConfig$ = this.selfService.getDtpConfig();
    this.relativeInputConfig$ = this.selfService.getRelativeInputConfig();
    this.relativeDdConfig$ = this.selfService.getRelativeDdConfig();
  }
}
