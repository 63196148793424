<div *ngIf="config" class="form-group" [class.hide-arrow]="hideArrows" [formGroup]="form">
  <label
    *ngIf="config?.label"
    [ngClass]="{ disabled: disabled, 'required-field': isRequired && config.showRequiredMarker && !disabled }"
    class="text-truncate w-100"
    title="{{ config.label }}"
    >{{ config.label }}</label
  >

  <input
    #input
    class="form-control"
    [ngClass]="{
      disabled: disabled,
      'border--danger': !disabled && hasError && control.touched
    }"
    formControlName="input"
    [id]="config.label"
    [placeholder]="config.placeholder"
    [type]="config.type"
    [autocomplete]="isAutocompleteEnabled()"
    [disabled]="disabled"
    [readonly]="disabled"
    [mask]="mask"
    [min]="config.min"
    [max]="config.max"
    [maxlength]="config.maxlength"
    [minlength]="config.minlength"
    [pattern]="pattern"
    (keyup)="onKeyup(input.value)"
    (blur)="onBlur(input.value)"
    (change)="onInputChange(input.value)"
  />

  <div
    class="invalid-feedback d-block"
    *ngIf="!disabled && hasError && control.touched"
  >
    <div *ngIf="hasError.required || hasError.invalid">
      {{ config.errors?.required }}
    </div>
    <div *ngIf="hasError.pattern">{{ config.errors?.pattern }}</div>
    <div *ngIf="hasError.minlength">{{ config.errors?.minlength }}</div>
    <div *ngIf="hasError.maxlength">{{ config.errors?.maxlength }}</div>
    <div *ngIf="hasError.min">{{ config.errors?.min }}</div>
    <div *ngIf="hasError.max">{{ config.errors?.max }}</div>
    <div *ngIf="hasError.ccNumber">{{ config.errors?.ccNumber }}</div>
    <div *ngIf="!hasError.pattern && hasError.expiryDateInvalid">{{ config.errors?.expiryDateInvalid }}</div>
  </div>
</div>
