import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { DepositPayee } from '@app/core/enums/deposit.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { RpFpdPreference } from '@app/core/enums/rp-fpd-preference.enum';
import { Frequency } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { PlanCalculationService } from '@app/core/services/plan-calculation.service';
import { DiscountType } from '@app/proposal-calculator/enums/discount.enum';
import { FpdItemsInternalId } from '@app/shared/components/first-payment-date-proposal/first-payment-date-proposal.component';
import { PlanCalculator } from '@app/shared/interfaces/plan-calculator.interface';
import { BehaviorSubject } from 'rxjs';
import { PaymentFrequency } from '../../enums/payment-frequency.enum';
import { TreatmentType } from '../../enums/treatment-type.enum';
import { FirstPaymentDateDdItem } from '../../models/common/form-fields.interface';
import { DDRRegex, maxDateValidator, minDateValidator } from '../ddr.validator';
import { FormFieldsService } from '../form-fields/form-fields.service';
import { UserSettingsService } from '../user-settings/user-settings.service';

@Injectable()
export class PlusConnectCalculatorService implements PlanCalculator {
  minDepositAmtDenOrtho = this.helperService.getObjUser()?.segment.zminDepositAmtDenOrtho;
  minTreatmentCost = 0;
  minPaymentPlanAmount = this.helperService.getObjUser()?.segment.custrecord_minimum_amount_plan;
  minimumDeposit = 0;
  maxDeposit = 0;
  today = new Date();
  tomorrow = this.planCalculationService.tomorrow;
  maxTreatmentCostDenOrtho = this.helperService.getObjUser()?.segment.zmaxTreatmentCostDenOrtho;
  maxTermMonths = this.helperService.getObjUser()?.segment.zmaxTermMonths;
  zmaxTotalTreatmentCost = this.helperService.getObjUser()?.segment.zmaxTotalTreatmentCost;
  maxPaymentPlan = this.helperService.getObjUser()?.segment.zmaxPaymentPlan;
  treatmentTypeSubject = new BehaviorSubject(TreatmentType.DENTAL); // Dental is default

  private paymentPlanAmount2k = 2000;

  constructor(
    private helperService: HelperService,
    private planCalculationService: PlanCalculationService,
    private userSettingsService: UserSettingsService,
    private formFieldsService: FormFieldsService
  ) {}

  getDefaultDepositPayee(): string {
    if (!this.userSettingsService.isPayDepositToEnabled()) {
      return DepositPayee.PRACTICE;
    }
    return this.userSettingsService.getDefaultDepositPayee()?.value;
  }

  getDiscountAmount(ttc: number, percentage: number): number {
    const discountedPrice = ttc * (parseFloat(percentage.toString()) / 100);
    return this.helperService.roundUpToTwoDecimal(discountedPrice);
  }

  getMaxTermMonths(formGroup: UntypedFormGroup): number {
    const totalPlanAmount = formGroup.get('paymentPlanTotal')?.value;
    const objUser = this.helperService.getObjUser();

    if (totalPlanAmount <= this.paymentPlanAmount2k) {
      if (objUser.segment.id[0].value === '2' && this.userSettingsService.isProactiveConnectOnlyProvider()) {
        // Ortho Segment && Connect Only
        return +this.maxTermMonths;
      } else {
        return +objUser.segment.custrecord_maximum_term_2000;
      }
    } else {
      return +objUser.segment.custrecord_maximum_term;
    }
  }

  getFrequencies(): Frequency[] {
    return this.helperService.getObjFormFields()?.custrecord_mfa_ddr_payment_frequency;
  }

  getMaxTreatmentCost(): number {
    const treatmentType = this.treatmentTypeSubject?.value;

    if (this.planCalculationService.isDentalProviderAndOrthoTreatment(treatmentType, PlanType.Guaranteed)) {
      return +this.maxTreatmentCostDenOrtho;
    }

    return this.zmaxTotalTreatmentCost;
  }

  getMaximumDeposit(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): number {
    let totalTreatmentCost = formGroup.get('totalTreatmentCost')?.value;

    if (discountType) {
      const discount = formGroup.get('discountAmount')?.value ? +formGroup.get('discountAmount')?.value : 0;

      totalTreatmentCost = this.helperService.roundUpToTwoDecimal(this.getTotalTreatmentCostWithDiscount(totalTreatmentCost, discount));
    }

    const ttcMinusMinPlanAmount = totalTreatmentCost - this.minPaymentPlanAmount;

    let maxDeposit = 0;

    // If clinic is dental ortho
    if (this.helperService.getObjUser()?.clinic_type === '2') {
      if (ttcMinusMinPlanAmount <= 0) {
        maxDeposit = 0;
      } else {
        if (totalTreatmentCost === this.minPaymentPlanAmount) {
          maxDeposit = totalTreatmentCost * this.getMinimumDeposit(formGroup, discountType);
        } else {
          maxDeposit = ttcMinusMinPlanAmount;
        }
      }
    } else {
      maxDeposit = ttcMinusMinPlanAmount > 0 ? ttcMinusMinPlanAmount : 0;
    }

    return this.helperService.roundUpToTwoDecimal(maxDeposit);
  }

  calculateMinTreatmentCost(treatmentType: string, planType: PlanType): number {
    if (this.planCalculationService.isDentalProviderAndOrthoTreatment(treatmentType, planType)) {
      return +this.minPaymentPlanAmount + this.minDepositAmtDenOrtho;
    }

    return +this.minPaymentPlanAmount / (1 - this.getDepositRatio());
  }

  setFieldValidators(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): void {
    this.minTreatmentCost = this.calculateMinTreatmentCost(this.treatmentTypeSubject?.value, PlanType.Guaranteed);
    this.minimumDeposit = this.getMinimumDeposit(formGroup, discountType);
    this.maxDeposit = this.getMaximumDeposit(formGroup, discountType);

    formGroup
      .get('totalTreatmentCost')
      ?.setValidators([Validators.required, Validators.min(this.minTreatmentCost), Validators.max(this.getMaxTreatmentCost())]);
    formGroup.get('totalTreatmentCost')?.updateValueAndValidity();

    formGroup
      .get('deposit')
      ?.setValidators([
        Validators.required,
        Validators.pattern(DDRRegex.getCurrencyValidation()),
        Validators.min(this.minimumDeposit),
        Validators.max(this.maxDeposit)
      ]);
    formGroup.get('deposit')?.updateValueAndValidity();

    formGroup
      .get('paymentPlanTotal')
      ?.setValidators([
        Validators.required,
        Validators.pattern(DDRRegex.getCurrencyValidation()),
        Validators.min(this.minPaymentPlanAmount)
      ]);
    formGroup.get('paymentPlanTotal')?.updateValueAndValidity();

    formGroup
      .get('quote.0.paymentPlanDurationInMonths')
      ?.setValidators([Validators.min(1), Validators.max(this.getMaxTermMonths(formGroup)), Validators.required]);
    formGroup.get('quote.0.paymentPlanDurationInMonths')?.updateValueAndValidity();

    // discount type determine if module type is proposal
    if (discountType) {
      if (formGroup.get('firstDebitDate.type')?.value === FpdItemsInternalId.Specific) {
        formGroup
          .get('firstDebitDate.specificDate')
          ?.setValidators([Validators.required, minDateValidator(this.tomorrow), maxDateValidator(this.getMaxPaymentStartDate())]);
        formGroup.get('firstDebitDate.specificDate')?.updateValueAndValidity();
      } else {
        formGroup.get('firstDebitDate.relativeOption')?.setValidators(Validators.required);
        formGroup.get('firstDebitDate.relativeInput')?.setValidators(Validators.required);

        const fpdItems = this.formFieldsService.getFpdOptions();
        const selectedFpdItem = fpdItems.find(
          (x) => x.internalid === formGroup.get('firstDebitDate.relativeOption')?.value
        ) as FirstPaymentDateDdItem;

        if (selectedFpdItem) {
          formGroup
            .get('firstDebitDate.relativeInput')
            ?.setValidators([Validators.min(1), Validators.max(+selectedFpdItem.max), Validators.required]);
        }

        formGroup.get('firstDebitDate.relativeOption')?.updateValueAndValidity();
        formGroup.get('firstDebitDate.relativeInput')?.updateValueAndValidity();

        formGroup.get('firstDebitDate.specificDate')?.setValidators(null);
        formGroup.get('firstDebitDate.specificDate')?.updateValueAndValidity();
      }
    } else {
      formGroup
        .get('firstDebitDate.specificDate')
        ?.setValidators([Validators.required, minDateValidator(this.tomorrow), maxDateValidator(this.getMaxPaymentStartDate())]);
      formGroup.get('firstDebitDate.specificDate')?.updateValueAndValidity();
    }
  }

  setTermMonths(formGroup: UntypedFormGroup): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;

    if (quoteGroup.controls[0].get('monthly')?.value) {
      const durationInMonthBefore = formGroup.get('paymentPlanTotal')?.value / quoteGroup.controls[0].get('monthly')?.value;
      const durationInMonth = Math.ceil(+durationInMonthBefore.toFixed(2));

      // Setting emitEvent false for setTermMonths since it is editable in Plus/Connect plan type
      quoteGroup.controls[0].get('paymentPlanDurationInMonths')?.setValue(durationInMonth, { emitEvent: false });
    }
  }

  getTermMonths(frequency: string): number {
    // return nothing since term months is manual input
    return 0;
  }

  setSinglePayment(formGroup: UntypedFormGroup, frequencies: Frequency[]): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;

    const quoteName = this.planCalculationService.setDDRSinglePayments(formGroup.get('paymentFrequency')?.value, frequencies);

    if (quoteName) {
      formGroup.get('singlePayments')?.setValue(quoteGroup.controls[0].get(quoteName)?.value);
    }
  }

  setNoOfPayments(formGroup: UntypedFormGroup): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;
    const termMonths = +quoteGroup.controls[0].get('paymentPlanDurationInMonths')?.value;
    formGroup.get('noOfPayments')?.setValue(this.getCalculateNoOfPayments(formGroup.get('paymentFrequency')?.value, termMonths));
  }

  getCalculateNoOfPayments(frequency: string, termMonths: number): number {
    const weeksPerYear = 52;

    switch (frequency) {
      case '1': {
        return Math.floor((termMonths / 12) * weeksPerYear);
      }
      case '2': {
        return Math.floor((termMonths / 12) * (weeksPerYear / 2));
      }
      case '3': {
        return termMonths;
      }
      default: {
        return 0;
      }
    }
  }

  setQuoteGroup(formGroup: UntypedFormGroup, setMonthlyAmount: boolean = true, discountType: DiscountType | null = null): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;
    const termMonths = +quoteGroup.controls[0].get('paymentPlanDurationInMonths')?.value;
    const amountRequiredToPay = this.getPaymentPlanAmount(formGroup, discountType);

    quoteGroup.controls.forEach((quote) => {
      let weeklyCost = null;
      let fortnightlyCost = null;
      let monthlyCost = null;

      if (amountRequiredToPay >= this.minPaymentPlanAmount) {
        weeklyCost = this.getAmountPerFrequency(this.getCalculateNoOfPayments('1', termMonths), amountRequiredToPay);
        fortnightlyCost = this.getAmountPerFrequency(this.getCalculateNoOfPayments('2', termMonths), amountRequiredToPay);
        monthlyCost = this.getAmountPerFrequency(this.getCalculateNoOfPayments('3', termMonths), amountRequiredToPay);

        weeklyCost = weeklyCost >= 0 ? weeklyCost : null;
        fortnightlyCost = fortnightlyCost >= 0 ? fortnightlyCost : null;
        monthlyCost = monthlyCost >= 0 ? monthlyCost : null;
      }

      quote.get('weekly')?.setValue(weeklyCost);
      quote.get('fortnightly')?.setValue(fortnightlyCost);
      if (setMonthlyAmount) {
        quote.get('monthly')?.setValue(monthlyCost);
      }
    });
  }

  setPaymentPlanAmount(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): void {
    formGroup.get('paymentPlanTotal')?.setValue(this.getPaymentPlanAmount(formGroup, discountType));
  }

  getPaymentPlanAmount(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): number {
    const totalPlanValue = formGroup.get('totalTreatmentCost')?.value ? +formGroup.get('totalTreatmentCost')?.value : 0;
    const depositAmount = formGroup.get('deposit')?.value ? +formGroup.get('deposit')?.value : 0;

    if (discountType) {
      const discount = formGroup.get('discountAmount')?.value ? +formGroup.get('discountAmount')?.value : 0;

      return this.helperService.roundUpToTwoDecimal(this.getTotalTreatmentCostWithDiscount(totalPlanValue, discount) - depositAmount);
    }

    return this.helperService.roundUpToTwoDecimal(totalPlanValue - depositAmount);
  }

  setMinimumDeposit(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): void {
    this.minimumDeposit = this.getMinimumDeposit(formGroup, discountType);
    const depositValue = formGroup.get('deposit')?.value;
    const deposit =
      depositValue > this.minimumDeposit && depositValue <= this.getMaximumDeposit(formGroup, discountType)
        ? depositValue
        : this.minimumDeposit;
    formGroup.get('deposit')?.setValue(this.helperService.roundUpToTwoDecimal(deposit));
  }

  getMinimumDeposit(formGroup: UntypedFormGroup, discountType: DiscountType | null = null): number {
    let totalPlanValue = formGroup.get('totalTreatmentCost')?.value ? +formGroup.get('totalTreatmentCost')?.value : 0;

    // For discount logic
    // TO DO: Move to new
    if (discountType) {
      const discount = formGroup.get('discountAmount')?.value ? +formGroup.get('discountAmount')?.value : 0;

      totalPlanValue = this.helperService.roundUpToTwoDecimal(this.getTotalTreatmentCostWithDiscount(totalPlanValue, discount));
    }

    const depositAmount = formGroup.get('deposit')?.value ? +formGroup.get('deposit')?.value : 0;
    const treatmentType = this.treatmentTypeSubject?.value;
    const planType = PlanType.Guaranteed;

    let minDeposit = 0;

    if (this.planCalculationService.isDentalProviderAndOrthoTreatment(treatmentType, planType)) {
      if (depositAmount > this.minDepositAmtDenOrtho) {
        minDeposit = depositAmount;
      } else {
        minDeposit = this.minDepositAmtDenOrtho;
      }
    } else {
      // Check if Ortho Provider & Ortho Treatment
      if (
        this.planCalculationService.isOthoProviderAndOrthoTreatment(treatmentType, planType) &&
        this.maxPaymentPlan < totalPlanValue &&
        totalPlanValue <= this.maxTreatmentCostDenOrtho
      ) {
        minDeposit = totalPlanValue - this.maxPaymentPlan; // Balance 14000-12000=200
      } else {
        minDeposit = totalPlanValue * this.getDepositRatio();
      }
    }

    return this.helperService.roundUpToTwoDecimal(minDeposit);
  }

  getDepositRatio(): number {
    return this.helperService.getObjUser()?.segment.zminDepositRatio;
  }

  /**
    Set to tomorrow
   */
  getDefaultFirstPaymentDate(): Date {
    const fpdPref = this.userSettingsService.getFpdPreference();
    if (fpdPref?.value === RpFpdPreference.FirstNextMonth) {
      const today = new Date();
      const firstNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      return firstNextMonth;
    }

    return this.helperService.addDaysToDate(1);
  }

  getMaxDiscountMsg(selectedDiscountType: DiscountType, maxDiscount: number): string {
    switch (selectedDiscountType) {
      case DiscountType.Currency: {
        return 'Maximum discount must be less than ' + this.helperService.convertToMoney(maxDiscount);
      }
      case DiscountType.Percentage: {
        return `Discount percentage cannot exceed ${Math.floor(maxDiscount)}%`;
      }
    }
  }

  getTotalTreatmentCostWithDiscount(ttc: number, discount: number): number {
    return this.helperService.roundUpToTwoDecimal(ttc - discount);
  }

  getMaxPaymentStartDate(): Date {
    return this.helperService.addDaysToDate(this.planCalculationService.maxStarDateInDays);
  }

  setTreatmentTypeSubj(treatmentType: TreatmentType): void {
    this.treatmentTypeSubject.next(treatmentType);
  }

  getMaxDiscount(discountType: DiscountType, totalTreatmentCost: number, deposit: number): number {
    switch (discountType) {
      case DiscountType.Currency: {
        // return totalTreatmentCost - deposit - this.calculateMinTreatmentCost();
        return +totalTreatmentCost;
      }
      case DiscountType.Percentage: {
        // const maxAmount = totalTreatmentCost - deposit - this.calculateMinTreatmentCost();
        return 100;
      }
    }
  }

  getMaxRepaymentAmountByFrequency(frequency: PaymentFrequency): number {
    const maxMonthlyRepaymentAmount = this.userSettingsService.getSegment().zmaxDDRMonth;

    switch (frequency) {
      case PaymentFrequency.Weekly: {
        return maxMonthlyRepaymentAmount / 4;
      }
      case PaymentFrequency.Fortnightly: {
        return maxMonthlyRepaymentAmount / 2;
      }
      case PaymentFrequency.Monthly: {
        return maxMonthlyRepaymentAmount;
      }
    }
  }

  private getAmountPerFrequency(numberOfPayments: number, paymentPlanAmount: number): number {
    const amountPerUnit = paymentPlanAmount / numberOfPayments;

    return numberOfPayments !== 0 ? this.helperService.roundUpToTwoDecimal(amountPerUnit ? Math.ceil(amountPerUnit * 20) / 20 : 0) : 0;
  }
}
