import { Injectable } from '@angular/core';

@Injectable()
export class PrintDownloadService {
  constructor() {}

  openPrintWindow(base64String: string): void {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a data URL for the Blob
    const dataUrl = URL.createObjectURL(blob);

    // Create a hidden iframe
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    // Set the iframe's source to the data URL
    iframe.src = dataUrl;

    // Wait for the iframe content to load
    iframe.onload = () => {
      // Trigger the print action from the iframe
      iframe.contentWindow?.print();
    };
  }
}
