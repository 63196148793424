import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sliqpay-copy-pending-record',
  templateUrl: './copy-pending-record.component.html',
  styleUrls: ['./copy-pending-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyPendingRecordComponent implements OnInit {
  @Input() recordType = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
