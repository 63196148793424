import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { AppRadioGroupConfig } from '@app/shared/interfaces/radio-group.interface';

@Component({
  selector: 'sliqpay-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent implements OnInit, ControlValueAccessor {
  @Input() config!: AppRadioGroupConfig | null;
  @Input() options: any[] = [];
  @Input() disabled?: boolean;

  onChange!: (value: any) => void;
  onTouched!: () => void;

  selectedValue: any;

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  get radioGroupName(): string {
    return this.control.name?.toString() || 'sliqpayRadioGroup';
  }

  get disableRadioGroup(): boolean {
    return this.control.disabled || this.config?.disabled || this.disabled ? true : false;
  }

  get hasError(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  get isRequired(): boolean {
    return this.control.control?.hasValidator(Validators.required) || false;
  }

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {}

  onOptionSelected(event: any): void {
    this.selectedValue = event.value;
    this.onChange(event.value);
    this.onTouched();
  }
}
