<div *ngIf="config" class="form-group hide-arrow" [ngClass]="config.containerClass">
  <label
    *ngIf="config?.label"
    [ngClass]="{ disabled: config.disabled, 'required-field': isRequired && config.showRequiredMarker && !disabled }"
    class="text-truncate w-100"
    >{{ config.label }}</label
  >

  <div class="number-input-group">
    <span class="dollar-symbol" [class.disabled]="disabled">
      <input
        [(ngModel)]="_value"
        #input
        type="number"
        class="form-control"
        autocomplete="off"
        [ngClass]="{ 'border--danger': hasError && control.touched && !disabled, 'text-right': config.textAlign === 'right' }"
        [id]="config.label"
        [placeholder]="config.placeholder || '0.00'"
        [disabled]="disabled"
        [readonly]="disabled"
        (focus)="onFocus()"
        (blur)="onBlur(input.value)"
        (keyup)="onKeyup(input.value)"
        [sliqpayNumeric]="{ allowNegative: config.allowNegative, allowDecimal: true }"
    /></span>

    <ng-container *ngIf="!disabled && hasError && control?.touched">
      <div class="invalid-feedback d-block">
        <div *ngIf="hasError.required || hasError.invalid">
          {{ config.errors?.required }}
        </div>
        <div *ngIf="hasError.min">{{ config.errors?.min }}</div>
        <div *ngIf="hasError.max">{{ config.errors?.max }}</div>
      </div>
    </ng-container>
  </div>
</div>
