import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SettingsService {
  saveAccountSettingsSubject = new Subject<boolean>();

  constructor() {}

  saveAccountSettingsClicked(): void {
    this.saveAccountSettingsSubject.next();
  }

  saveAccountSettingsClickListener$(): Observable<boolean> {
    return this.saveAccountSettingsSubject.asObservable();
  }
}
