<form [formGroup]="payInFullForm">
  <h6 [innerHTML]="'header' | lang: LANG_CONFIG_KEY | async"></h6>

  <div class="row">
    <div class="col-md-3">
      <sliqpay-currency-input
        formControlName="fullPaymentTotalTreatmentCost"
        [config]="totalTreatmentCostConfig$ | async"
      >
      </sliqpay-currency-input>

      <div
        class="invalid-feedback d-block"
        *ngIf="pifTotalTreatmentCost?.errors && (pifTotalTreatmentCost?.touched || pifTotalTreatmentCost?.value)"
      >
        <div *ngIf="pifTotalTreatmentCost?.hasError('max')">
          {{ maxPifTreatmentCostErrorMsg }}
        </div>
      </div>
    </div>
    <div class="icon-ctnr">
      <i class="uil uil-minus"></i>
    </div>
    <div class="col-md-3">
      <sliqpay-dynamic-discount
        [amountToDiscount]="pifTotalTreatmentCost?.value"
        [discountData]="{
            discountAmount: pifDiscount?.value,
            discountAsPercentage: pifDiscountAsPercentage?.value,
            discountType: pifDiscountType?.value
        }"
        [disableFields]="pifDiscount?.disabled || false"
        (discountDataChange)="dynamicDiscountChanged($event)"
      ></sliqpay-dynamic-discount>
    </div>
    <div class="icon-ctnr">
      <i class="uil uil-minus"></i>
    </div>
    <div class="col-md-3">
      <sliqpay-currency-input formControlName="fullPaymentOtherDiscount" [config]="otherDiscountConfig$ | async">
      </sliqpay-currency-input>
    </div>
    <div class="icon-ctnr">
      <span class="icon-equals"></span>
    </div>
    <div class="col-md-3 payment-total-ctnr">
      <sliqpay-currency-input formControlName="fullPaymentNetTotal" [config]="paymentPlanTotalConfig$ | async">
      </sliqpay-currency-input>
      <div class="invalid-feedback d-block" *ngIf="pifNetAmount?.hasError('min')">
        <div>
          {{ pifTotalAmountErrMsg }}
        </div>
      </div>
    </div>
  </div>
</form>
